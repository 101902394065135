import { Box, Divider } from "@mui/material";
import DetailShopName from "./DetailShopName";
import ExchangeCurrency from "../ExchangeCurrency";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getShopFromProductDetail } from "../../apicalls";

export default function ProductDetailShop({pguid}) {
  const [shopName, setShopName] = useState([]);
  const [loading , setLoading] = useState(true)

  useEffect(()=>{
    (async()=>{
      const result = await getShopFromProductDetail(pguid);
      setShopName(result.data.items);
      // console.log(shopName);
    })()
  },[pguid]);
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "350px",
        border: "1px solid grey",
        "@media (max-width: 600px)": {
          maxWidth: "100%",
          padding: "5px",
        },
      }}
    >
      <Box sx={{ padding: "10px" }}>
        <DetailShopName pguid={pguid}/>
        <Divider />
        <ExchangeCurrency />
      </Box>
    </Box>
  );
}
