import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "../../product.css";
import { useAuth } from "../../DetailProvider";
import { api } from "../../apicalls";

const ProductPhoto = ({ variantDetailImg }) => {
  const [selectedImageGuid, setSelectedImageGuid] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const {
    detailInfo,
    setDetailInfo,
    loadingContext,
    setLoadingContext,
    selectImgae,
  } = useAuth();

  useEffect(() => {
    // Check if there is a selected image
    if (selectImgae) {
      // Find the index of the selected image in variantDetailImg
      const selectedIndex = variantDetailImg.findIndex(
        (vdi) => vdi.variantDetailGuid === selectImgae
      );

      // If the selected image is found, update the selectedImageGuid state
      if (selectedIndex !== -1) {
        setSelectedImageGuid(selectedIndex);
      }
    }
  }, [selectImgae, variantDetailImg]);

  const handleImageChange = (index) => {
    // Update the selectedImageGuid state when the image changes
    setSelectedImageGuid(index);
  };

  const renderArrowNext = (onClickHandler, hasNext, label) => {
    if (hasNext) {
      return (
        <button
          type="button"
          onClick={onClickHandler}
          title={label}
          className="carousel-arrow next"
        >
          Next
        </button>
      );
    }
    return null;
  };

  const renderArrowPrev = (onClickHandler, hasPrev, label) => {
    if (hasPrev) {
      return (
        <button
          type="button"
          onClick={onClickHandler}
          title={label}
          className="carousel-arrow prev"
        >
          ok
        </button>
      );
    }
    return null;
  };

  return (
    <div>
      <Carousel
        autoFocus
        infiniteLoop
        renderArrowNext={renderArrowNext}
        renderArrowPrev={renderArrowPrev}
        showArrows={false}
        showStatus={false}
        showThumbs={true}
        showIndicators={false}
        selectedItem={selectedImageGuid}
        onChange={handleImageChange}
      >
        {variantDetailImg.map((vdi) => {
          return (
            <div key={vdi.guid}>
              <img
                src={`${api}/v1/image/download/${vdi.image}`}
                alt="Slide 1"
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default ProductPhoto;
