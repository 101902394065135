import React, { useEffect } from "react";
import {
    Autocomplete,
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import deliPlan from "../../apicalls";

const DeliveryInformation = ({
    name,
    nameChange,
    phone,
    phoneChange,
    email,
    emailChange,
    address,
    addressChange,
    states,
    state,
    stateChange,
    townships,
    township,
    townshipChange,
    streets,
    street,
    streetChange,
    fragile,
    fragileChange,
    insurance,
    insuranceChange,
    gate,
    gateChange,
    note,
    noteChange,
    base64String,
    oldAddresses,
    selectedAddress,
    setSelectedAddress,
    setName,
    setPhone,
    setEmail,
    setAddress,
    setStreet,
    setState,
    setTownship,
}) => {
    // console.log(states);
    // let shopLists = [];
    // const shops = JSON.parse(localStorage.getItem("cart"));
    // shops.map(shop => {
    //     return shopLists.push(shop.shopGuid);
    // });
    // console.log(shopLists)

    const cartData = JSON.parse(localStorage.getItem("cart"));

    const shopList = {};
    cartData?.map(data => {
        if (shopList[data.shopGuid]) {
            var dataArray = shopList[data.shopGuid];
            dataArray.push(data);
        } else {
            let dataArray = [];
            dataArray.push(data);
            shopList[data.shopGuid] = dataArray;
        }
        return data;
    });
    let uniqueShop = [];

    for (const [key, value] of Object.entries(shopList)) {
        uniqueShop.push(key);
        // (async () => {
        //     const res = await deliPlan(
        //         uniqueShop,
        //         township,
        //         fragile,
        //         insurance
        //     );
        //     console.log(res);
        // })();
        // console.log(uniqueShop);
    }

    const handleAddressChange = (e, values) => {
        setSelectedAddress(values);
        console.log(values);
        setName(values.name);
        setPhone(values.primaryPhone);
        setEmail(values.email);
        setAddress(values.address);
        setStreet(values.street);
        setState(values.stateGuid);
        setTownship(values.townshipGuid);
        uniqueShop?.map(shop => {
            (async () => {
                const deliMethod = await deliPlan(
                    shop,
                    values.townshipGuid,
                    fragile,
                    insurance
                );
                localStorage.setItem("deliPlan", [JSON.stringify(deliMethod)]);
                console.log(deliMethod);
            })();
        });
    };

    return (
        <>
            <Card sx={{ maxWidth: "full" }} elevation={1}>
                <CardContent>
                    {/* <form> */}
                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                        Delivery Information
                    </Typography>
                    <Box
                        sx={{
                            gap: "24px",
                        }}
                    >
                        {/* <Autocomplete
                        fullWidth
                            disablePortal
                            id="combo-box-demo"
                            options={oldAddresses}
                            // sx={{ width: 300 }}
                            renderInput={params => (
                                <TextField {...params} label="Old Addresses" />
                            )}
                        /> */}

                        <Autocomplete
                            sx={{ marginTop: "16px" }}
                            id="employeeID"
                            options={oldAddresses}
                            value={selectedAddress}
                            autoHighlight
                            getOptionLabel={option => option.township}
                            // renderOption={option => (
                            //     <CardHeader
                            //         title={
                            //             <Typography variant="body1">
                            //                 {option.name}
                            //             </Typography>
                            //         }
                            //         subheader={""}
                            //         avatar={
                            //             <Avatar
                            //                 alt={option.name}
                            //                 src={
                            //                     option.avatar
                            //                         ? this.resources
                            //                               .BACKEND_SIDE_BASE_URL +
                            //                           this.config
                            //                               .SERVICE_NAME_IMAGE +
                            //                           this.config
                            //                               .SERVICE_ACTION_IMAGE_DOWNLOAD +
                            //                           option.avatar
                            //                         : option.name[0].toUpperCase()
                            //                 }
                            //             />
                            //         }
                            //     />
                            // )}
                            // size="small"
                            onChange={handleAddressChange}
                            style={{ width: "100%" }}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={"Select Old Addresses"}
                                    variant="outlined"
                                    // error={
                                    //     this.state.errorsOS.assignee
                                    //         ? true
                                    //         : false
                                    // }
                                    // helperText={this.state.errorsOS.assignee}
                                />
                            )}
                        />
                        <TextField
                            sx={{ marginTop: "16px" }}
                            label="Full Name"
                            value={name}
                            onChange={nameChange}
                            fullWidth
                            required
                        />
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "24px",
                                marginY: "16px",
                            }}
                            fullWidth
                        >
                            <TextField
                                label="Phone Number"
                                type="phone"
                                sx={{ width: "50%" }}
                                value={phone}
                                onChange={phoneChange}
                                required
                            />
                            <TextField
                                label="Email"
                                type="email"
                                value={email}
                                onChange={emailChange}
                                sx={{ width: "50%" }}
                                required
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "24px",
                                marginTop: "16px",
                            }}
                            fullWidth
                        >
                            <TextField
                                label="House No. (or) Unit, Floor"
                                value={address}
                                onChange={addressChange}
                                fullWidth
                                required
                            />
                            <TextField
                                label="Street"
                                value={street}
                                onChange={streetChange}
                                fullWidth
                                required
                            />
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "24px",
                                marginTop: "16px",
                            }}
                            fullWidth
                        >
                            <FormControl sx={{ width: "50%" }} required>
                                <InputLabel id="demo-simple-select-label">
                                    State
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="State"
                                    value={state}
                                    onChange={stateChange}
                                >
                                    {states?.map(state => (
                                        <MenuItem
                                            key={state.key}
                                            value={state.guid}
                                        >
                                            {state.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: "50%" }} required>
                                <InputLabel id="demo-simple-select-label">
                                    Township
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Township"
                                    value={township}
                                    onChange={e => {
                                        townshipChange(e);
                                        uniqueShop?.map(shop => {
                                            (async () => {
                                                const deliMethod =
                                                    await deliPlan(
                                                        shop,
                                                        e.target.value,
                                                        fragile,
                                                        insurance
                                                    );
                                                localStorage.setItem(
                                                    "deliPlan",
                                                    [JSON.stringify(deliMethod)]
                                                );
                                                console.log(deliMethod);
                                            })();
                                        });
                                    }}
                                >
                                    {townships?.map(township => (
                                        <MenuItem
                                            key={township.key}
                                            value={township.guid}
                                        >
                                            {township.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {/* <FormControl sx={{ width: "50%" }}>
                                <InputLabel id="demo-simple-select-label">
                                    Street
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Street"
                                >
                                    {
                                        streets?.map(street => (
                                            <MenuItem key={street.key} value={street.guid}>{street.name}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl> */}
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            {township !== "123" && (
                                <TextField
                                    fullWidth
                                    label="Highway Gate"
                                    value={gate}
                                    onChange={gateChange}
                                />
                            )}
                        </Box>
                    </Box>
                    {/* </form> */}
                </CardContent>
            </Card>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Card
                    sx={{
                        width: "100%",
                        height: "auto",
                        alignItems: "left",
                        position: "sticky",
                    }}
                >
                    <CardContent
                        sx={{
                            mb: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                Services
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={insurance}
                                            onChange={insuranceChange}
                                        />
                                    }
                                    label="Warranty Service Request"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={fragile}
                                            onChange={fragileChange}
                                        />
                                    }
                                    label="Product maintenance (Fragile)"
                                />
                            </FormGroup>
                            {/* <Typography
                                variant="body1"
                                component="p"
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "140%",
                                }}
                                align="start"
                            >
                                Lorem ipsum dolor sit amet consectetur. In amet
                                ac turpis non. Ut molestie sit{" "}
                            </Typography> */}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            <Box>
                <Card sx={{ maxWidth: "full" }} elevation={1}>
                    <CardContent>
                        <Typography
                            gutterBottom
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: "bold" }}
                        >
                            Additional notes (optional)
                        </Typography>
                        <TextField
                            label="Add notes about your order"
                            type="text"
                            value={note}
                            onChange={noteChange}
                            sx={{ width: "100%" }}
                        />
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default DeliveryInformation;
