import { Avatar, Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ShopIcon from '@mui/icons-material/Shop';
import AppleIcon from '@mui/icons-material/Apple';

export default function ConnectWithUs() {
    return (
        <Box>
            <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                Connect With Us
            </Typography>
            <Avatar
                alt="Facebook"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Facebook_Logo_%282019%29.png/1024px-Facebook_Logo_%282019%29.png"
                sx={{
                    width: 50,
                    height: 50,
                    display: "inline-block",
                    marginRight: "10px",
                }}
            />
            <Avatar
                alt="Viber"
                src="https://e7.pngegg.com/pngimages/605/872/png-clipart-viber-logo-viber-installation-messaging-apps-mobile-phones-text-messaging-whatsapp-purple-violet.png"
                sx={{
                    width: 50,
                    height: 50,
                    display: "inline-block",
                    marginRight: "10px",
                }}
            />
            <Box>
                <List>
                    <ListItem disablePadding sx={{bgcolor:"black" , borderRadius:"10px" , mb:2}}>
                        <ListItemButton>
                            <ListItemIcon >
                                <ShopIcon sx={{color:"white"}}/>
                            </ListItemIcon>
                            <ListItemText primary="Play Store" sx={{marginLeft:"-20px"}}/>
                        </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding sx={{bgcolor:"black" , borderRadius:"10px"}}>
                        <ListItemButton>
                            <ListItemIcon >
                                <AppleIcon sx={{color:"white"}}/>
                            </ListItemIcon>
                            <ListItemText primary="App Store" sx={{marginLeft:"-20px"}}/>
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
    );
}
