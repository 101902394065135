import {
    Avatar,
    Box,
    Button,
    Card,
    Menu,
    MenuItem,
    Modal,
    OutlinedInput,
    Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
    getLogin,
    getNewPassword,
    verifyAccount,
    getRefreshToken,
} from "../../apicalls";
import { TextField } from "@mui/material";
import { useAuth } from "../../DetailProvider";

const Login = () => {
    const nameRef = useRef();
    const passwordRef = useRef();
    const navigate = useNavigate();
    const [showMenu, setShowMenu] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [newPasswordPhoneNumber, setNewPasswordPhoneNumber] = useState("");
    const retoken = localStorage.getItem("refresh_token");
    const expires = localStorage.getItem("expires_in");

    const { auth, setAuth, authUser, setAuthUser } = useAuth();

    const handleFormSubmit = e => {
        e.preventDefault(); // Prevent default form submission behavior

        const name = nameRef.current.value;
        const password = passwordRef.current.value;

        // Make the API call using the getLogin function with the email and password
        getLogin(name, password)
            .then(response => {
                // Check if the login was successful
                if (response) {
                    // Set authentication state to true
                    setAuth(true);

                    // Store user information in authUser object
                    const {
                        access_token,
                        token_type,
                        refresh_token,
                        expires_in,
                    } = response.data;
                    const user = {
                        username: name,
                        access_token,
                        token_type,
                        refresh_token,
                        expires_in,
                    };

                    (async () => {
                        const res = await verifyAccount(access_token);
                        if (res.data.verified === false) {
                            return navigate("/verify-account");
                        } else {
                            return navigate("/");
                        }
                    })();

                    console.log(refresh_token);

                    setTimeout(() => {
                        getRefreshToken(refresh_token);
                    }, expires_in);

                    localStorage.setItem("auth", JSON.stringify(true));
                    localStorage.setItem("authUser", JSON.stringify(user));

                    // Navigate to the desired location (e.g., home page)
                    navigate("/");
                } else {
                    // Handle unsuccessful login (e.g., show error message)
                    console.log("Login unsuccessful.");
                }
            })
            .catch(error => {
                // Handle API call errors here (e.g., show error message)
                console.error("API error:", error);
            });
    };

    return (
        <Box sx={{ background: "red", height: "100vh" }}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "end",
                    height: 110,
                }}
            >
                <Avatar sx={{ width: 98, height: 98, mb: -5 }} />
            </Box>
            <Box
                sx={{
                    mx: {
                        xs: 2, // 2 units of margin on extra-small screens (mobile)
                        sm: 4, // 4 units of margin on small screens (tablet)
                        md: 5, // 5 units of margin on medium screens
                        lg: 40, // 40 units of margin on large screens (laptop and above)
                    },
                }}
                align="center"
            >
                <Card
                    sx={{
                        width: {
                            xs: "100%", // Full width on extra-small screens (mobile)
                            sm: "100%", // 80% width on small screens (tablet)
                            md: "60%", // 60% width on medium screens
                            lg: "433px", // Fixed width of 433px on large screens (laptop and above)
                        },
                        paddingX: {
                            xs: 2, // 2 units of padding on extra-small screens (mobile)
                            sm: 4, // 4 units of padding on small screens (tablet)
                            md: 5, // 5 units of padding on medium screens
                            lg: 5, // 5 units of padding on large screens (laptop and above)
                        },
                        paddingBottom: {
                            xs: 2, // 2 units of padding on extra-small screens (mobile)
                            sm: 4, // 4 units of padding on small screens (tablet)
                            md: 5, // 5 units of padding on medium screens
                            lg: 5, // 5 units of padding on large screens (laptop and above)
                        },
                        paddingTop: {
                            xs: 1, // 1 unit of padding on extra-small screens (mobile)
                            sm: 2, // 2 units of padding on small screens (tablet)
                            md: 3, // 3 units of padding on medium screens
                            lg: 3, // 3 units of padding on large screens (laptop and above)
                        },
                    }}
                    elevation={5}
                >
                    <Typography variant="h5" sx={{ my: 3 }} align="center">
                        Login Form
                    </Typography>
                    <form onSubmit={handleFormSubmit}>
                        <OutlinedInput
                            placeholder="Username"
                            fullWidth
                            sx={{ mb: 2 }}
                            inputRef={nameRef}
                        />
                        <OutlinedInput
                            type="password"
                            placeholder="Password"
                            fullWidth
                            sx={{ mb: 2 }}
                            inputRef={passwordRef}
                        />
                        <Button
                            sx={{ m: 2 }}
                            align="end"
                            onClick={e => {
                                setShowMenu(e.currentTarget);
                            }}
                        >
                            Forget Password?
                        </Button>
                        <Menu
                            open={Boolean(showMenu)}
                            anchorEl={showMenu}
                            onClose={() => {
                                setShowMenu(false);
                            }}
                        >
                            <MenuItem sx={{ width: 300 }}>
                                <TextField
                                    label="Enter Phone Number"
                                    fullWidth
                                    variant="outlined"
                                    value={newPasswordPhoneNumber} // Use newPasswordPhoneNumber
                                    onChange={e =>
                                        setNewPasswordPhoneNumber(
                                            e.target.value
                                        )
                                    } // Update newPasswordPhoneNumber state
                                />
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        // Handle phone number submission here
                                        console.log(
                                            "Phone number submitted:",
                                            newPasswordPhoneNumber
                                        );
                                        // Make API call to getNewPassword with newPasswordPhoneNumber
                                        getNewPassword(newPasswordPhoneNumber)
                                            .then(response => {
                                                // Handle the API response here
                                                console.log(
                                                    "New password response:",
                                                    response
                                                );
                                            })
                                            .catch(error => {
                                                // Handle API call errors here
                                                console.error(
                                                    "New password error:",
                                                    error
                                                );
                                            });

                                        setShowMenu(false);
                                    }}
                                    sx={{ marginTop: 2 }}
                                >
                                    Submit
                                </Button>
                            </MenuItem>
                        </Menu>
                        <Button
                            type="submit"
                            variant="contained"
                            fullWidth
                            sx={{ color: "primary" }}
                        >
                            Login
                        </Button>
                    </form>
                    <Typography sx={{ m: 2 }}>
                        Don’t have an account?{" "}
                        <Link
                            to="/register"
                            style={{
                                textDecoration: "none",
                                color: "blue",
                                width: "100%",
                            }}
                        >
                            Sign Up
                        </Link>
                    </Typography>
                </Card>
            </Box>
        </Box>
    );
};

export default Login;
