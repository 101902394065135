import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import FooterLogo from "./FooterLogo";
import ExploreMore from "./ExploreMore";
import ConnectWithUs from "./ConnectWithUs";

const Footer = () => {
    return (
        <Box sx={{ backgroundColor: "red", color: "white" }}>
            <footer>
                <Container
                    maxWidth="lg"
                    sx={{ backgroundColor: "red", color: "white" }}
                >
                    <Grid
                        container
                        justifyContent="center"
                        sx={{ paddingTop: "10px" }}
                    >
                        <Grid item xs={12}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                }}
                            >
                                <Box>
                                    <FooterLogo />
                                </Box>
                                <Box>
                                    <ExploreMore />
                                </Box>
                                <Box>
                                    <ConnectWithUs />
                                </Box>
                            </Box>
                            <Typography
                                variant="body2"
                                sx={{ textAlign: "center" }}
                            >
                                &copy; {new Date().getFullYear()} Your
                                Q-Commerce Website. All rights reserved.
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </footer>
        </Box>
    );
};

export default Footer;
