import {
    Box,
    Breadcrumbs,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    InputBase,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getallshops } from "../apicalls";
import "../text.css";
import { useAuth } from "../DetailProvider";
import Search from "../Components/SingleShop/Search";

export default function AllShops() {
    const navigate = useNavigate();
    const { translate, setTranslate } = useAuth();
    const [searchOpen, setSearchOpen] = useState(false);
    const [results, setResults] = useState([]);
    const [input, setInput] = useState("");
    const [open, setOpen] = useState(false);

    const [allShops, setAllShops] = useState([]);
    const [loading, setLoading] = useState(true);

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="black"
            variant="h6"
            href="/"
            onClick={() => navigate("/")}
        >
            {translate.Home}
        </Link>,
        <Link
            underline="hover"
            variant="h6"
            key="2"
            color="black"
            onClick={() => navigate("")}
        >
            {translate.Shops}
        </Link>,
    ];
    useEffect(() => {
        (async () => {
            const result = await getallshops();
            setAllShops(result.data.items);
            setLoading(false);
            console.log(result);
        })();
    }, []);
    const fetchData = value => {
        fetch(
            "https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/pos/shop/listAllShop"
        )
            .then(response => response.json())
            .then(json => {
                const results = json.data.items.filter(user => {
                    return (
                        value &&
                        user &&
                        user.name &&
                        user.name.toLowerCase().includes(value)
                    );
                });
                setResults(results);

                // Set the open state based on whether there is data in results
                setOpen(results.length > 0);

                console.log(results);
            });
    };

    const handleChange = value => {
        setInput(value);
        fetchData(value);
    };

    return (
        !loading && (
            <Box sx={{ p: "30px 50px 20px 50px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Stack spacing={2}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pt: "50px",
                    }}
                >
                    <Box>
                        <Typography variant="h5">
                            {translate.AllShops}
                        </Typography>
                    </Box>
                    <Box>
                        <Paper
                            component="form"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                width: 600,
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search"
                                inputProps={{
                                    "aria-label": "Search",
                                }}
                                value={input}
                                onChange={e => {
                                    handleChange(e.target.value);
                                }}
                            />

                            <IconButton type="button" aria-label="search">
                                <SearchIcon />
                            </IconButton>
                        </Paper>
                        {results && (
                            <Box sx={{position:"sticky"}}>
                                {results.map(res => {
                                    return (
                                        <List sx={{bgcolor:"white"}}>
                                            <ListItem disablePadding>
                                                <ListItemButton
                                                    onClick={() => {
                                                        navigate(
                                                            `/view-one-shop/${res.guid}`
                                                        );
                                                        handleChange();
                                                    }}
                                                >
                                                    <ListItemText
                                                        primary={res.name}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        </List>
                                    );
                                })}
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box sx={{mt:5}}>
                    <Box >
                        <Grid container spacing={1}>
                            {allShops.map(shop => {
                                return (
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        key={shop.guid}
                                        sx={{ pb: 2 }}
                                    >
                                        <Card
                                            sx={{ maxWidth: 200 }}
                                            onClick={() =>
                                                navigate(
                                                    `/view-one-shop/${shop.guid}`
                                                )
                                            }
                                        >
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    sx={{objectFit:"cover"}}
                                                    image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${shop.logo}`}
                                                    alt="Shop Logo"
                                                />
                                                <CardContent
                                                    sx={{
                                                        textAlign: "center",
                                                        mb: -2,
                                                    }}
                                                >
                                                    <Divider />
                                                    <Typography
                                                        variant="h5"
                                                        className="shopNameText"
                                                    >
                                                        {shop.name}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        )
    );
}
