import {
    Box,
    Breadcrumbs,
    Link,
    Stack,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../text.css";
import {
    getDescription,
    getProductDetails,
    getProductVariantDetail,
} from "../apicalls";
import { useAuth } from "../DetailProvider";
import ProductInfo from "../Components/OneProductDetail/ProductInfo";
import ProductPhoto from "../Components/OneProductDetail/ProductPhoto";
import TwoTabs from "../Components/OneProductDetail/TwoTabs";
import ProductDetailShop from "../Components/OneProductDetail/ProductDetailShop";

export default function OneProductDetail() {
    const { cguid, pguid } = useParams();
    const navigate = useNavigate();
    // const [proDetails, setProDetails] = useState([]);
    const [proInfo, setProInfo] = useState(null);
    const { detailInfo, setDetailInfo , setWhistLists } = useAuth();
    const [error, setError] = useState(false);
    const [descri, setDescri] = useState("");
    const [variantDetail, setVariantDetail] = useState({});
    const [variantDetailImg, setVariantDetailImg] = useState([]);
    const [variantDetailItems, setVariantDetailItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [categ,setCateg] = useState("");

    useEffect(() => {
        (async () => {
            const result = await getDescription(pguid);
            if (result.data.items && result.data.items.length > 0) {
                setDescri(result.data.items[0].description);
            } else {
                setDescri("No description available");
            }
        })();
    }, [pguid]);

    useEffect(() => {
        (async () => {
            const result = await getProductVariantDetail(pguid);
            setVariantDetail(result.data);
            setVariantDetailImg(result.data?.imageItems);
            setVariantDetailItems(result.data.variantDetailItems);
            // console.log(result.data);
            // console.log(result.data.imageItems);
            // console.log(result.data.variantDetailItems);
    
            // Check if categories exist before setting categ state
            if (result.data.categories && result.data.categories.length > 0) {
                setCateg(result.data.categories[0]);
            } else {
                // Set a default category value or handle the scenario as needed
                setCateg("Default Category");
            }
    
            setLoading(false);
        })();
    }, [pguid]);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const result = await getProductVariantDetail(pguid);
    //             if (result.data && result.data.items) {
    //                 setVariantDetail(result.data.items);
    //                 console.log(result.data.items);
    //                 // console.log(proDetails);
    //                 if (result.data.items.length === 0) {
    //                     setError(true);
    //                 } else {
    //                     setError(false);
    //                 }
    //             } else {
    //                 setError(true);
    //             }
    //         } catch (error) {
    //             setError(true);
    //         }
    //     })();
    // }, [pguid]);

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const result = await getProductDetails(pguid);
    //             if (result.data && result.data.items) {
    //                 setProDetails(result.data.items);
    //                 // console.log(result.data.items);
    //                 // console.log(proDetails);
    //                 if (result.data.items.length === 0) {
    //                     setError(true);
    //                 } else {
    //                     setError(false);
    //                 }
    //             } else {
    //                 setError(true);
    //             }
    //         } catch (error) {
    //             setError(true);
    //         }
    //     })();
    // }, [pguid]);

    // useEffect(() => {
    //     setProInfo(
    //         proDetails.filter(proDetail => proDetail.guid === detailInfo)[0]
    //     );
    // }, [detailInfo, proDetails]);

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="black"
            variant="h6"
            href="/"
            onClick={() => navigate("/")}
        >
            Home
        </Link>,
        // <Link
        //     underline="hover"
        //     variant="h6"
        //     key="2"
        //     color="black"
        //     onClick={() => navigate(`/viewproductcategory/${cguid}`)}
        // >
        //     Category
        // </Link>,
        <Link
            underline="hover"
            variant="h6"
            key="3"
            color="black"
            onClick={() => navigate("")}
        >
            {variantDetail?.name}
        </Link>,
    ];

    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <Box sx={{ p: "30px 50px 20px 50px" }}>
            {error ? (
                <Box sx={{ textAlign: "center", height: "60vh" }}>
                    <Typography
                        variant="h5"
                        sx={{ color: "orangered", marginTop: 30 }}
                    >
                        No product found in that Category.
                    </Typography>
                    {/* You can add more descriptive error messages or actions here */}
                </Box>
            ) : (
                <>
                    <Box
                        sx={{ display: "flex", alignItems: "center" }}
                        id="rrr"
                    >
                        <Stack spacing={2}>
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Stack>
                    </Box>
                    {/* {!error &&
                        variantDetail.length > 0 && ( // Add this condition */}
                    {!loading && (
                        <Box
                            sx={{
                                display: "flex",
                                paddingTop: "40px",
                                justifyContent: "space-around",
                                flexDirection: isMobile
                                    ? "column"
                                    : ["row", "row", "row"],
                            }}
                        >
                            <Box
                                sx={{
                                    width: isMobile ? "100%" : [320, 420, 420],
                                    height: isMobile ? "auto" : [370, 470, 470],
                                    mb: isMobile ? 3 : [30, 10, 10],
                                }}
                            >
                                <ProductPhoto
                                    variantDetailImg={variantDetailImg}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: isMobile ? "100%" : [320, 550, 550],
                                    height: isMobile ? "auto" : [370, 470, 470],
                                    mb: isMobile ? 3 : [15, 0, 0],
                                }}
                            >
                                <ProductInfo
                                    variantDetailItems={variantDetailItems}
                                    variantDetail={variantDetail}
                                    proInfo={proInfo}
                                    pguid={pguid}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: isMobile ? "100%" : [320, 420, 420],
                                    height: isMobile ? "auto" : [370, 470, 470],
                                }}
                            >
                                <ProductDetailShop
                                    pguid={pguid}
                                    variantDetail={variantDetail}
                                />
                            </Box>
                        </Box>
                    )}

                    {/* )} */}
                    {/* {!error &&
                        variantDetail.length > 0 && ( // Add this condition */}
                    <Box
                        id="okns"
                        sx={{
                            display: "flex",
                            marginTop: "200px",
                        }}
                    >
                        <TwoTabs descri={descri} categ={cguid} pguid={pguid}/>
                    </Box>
                    {/* )} */}
                </>
            )}
        </Box>
    );
}
