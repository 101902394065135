import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../DetailProvider";
import { api } from "../../apicalls";

export default function ShopsProducts({ product }) {
    // /oneproductdetail/:cguid/:pguid
    const { guid } = useParams();
    const { translate } = useAuth();
    const navigate = useNavigate();
    useEffect(() => {
        console.log(guid);
        console.log(product);
    }, [guid]);
    return (
        <Card
            sx={{ maxWidth: 350 }}
            onClick={() => {
                navigate(`/oneproductdetail/${guid}/${product.productGuid}`);
            }}
        >
            <CardActionArea>
                <CardMedia
                    component="img"
                    height="280"
                    sx={{
                        marginTop: "10px",
                        objectFit: "cover",
                        height: "280",
                    }}
                    image={`${api}/v1/image/download/${product.photo}?size=280`}
                    alt="green iguana"
                />
                <CardContent sx={{ textAlign: "left", mb: -2 }}>
                    <Divider />
                    <Typography variant="h5" className="shopNameText">
                        {product.displayName}
                    </Typography>
                    <Typography variant="h6" className="shopNameText">
                        {translate.KS} : {product.priceSell * product.rate}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
