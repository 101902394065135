import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CheckoutSummary from "../Components/Checkout/CheckoutSummary";
import LocalDeliveryInformation from "../Components/Checkout/LocalDeliveryInformation";
import CheckoutShopList from "../Components/Checkout/CheckoutShopList";
import CheckoutAdditionNotes from "../Components/Checkout/CheckoutAdditionNotes";
import ForeignDeliveryInfo from "../Components/Checkout/ForeignDeliveryInfo";
import CheckoutPaymentMethod from "../Components/Checkout/CheckoutPaymentMethod";
import ExchangeRate from "../Components/ExchangeRate";
import {
    createOrder,
    getAllCountries,
    getAllShippingContacts,
    getAllStates,
    getCurrency,
    getShippingContact,
    getTownshipsWithState,
} from "../apicalls";
import TermsAndConditions from "../Components/Checkout/TermsAndConditions";
import { usePaymentMethod } from "../Contexts/PaymentMethodContext";

const Checkout = () => {
    // Delivery Information

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [state, setState] = useState("");
    const [states, setStates] = useState([]);
    const [township, setTownship] = useState("");
    const [townships, setTownships] = useState([]);
    const [street, setStreet] = useState("");
    const [note, setNote] = useState("");
    const [fragile, setFragile] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [gate, setGate] = useState("");
    const [oldAddresses, setOldAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [finalize, setFinalize] = useState("");
    const [frontOrHand, setFrontOrHand] = useState("");
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState("");
    const [zip, setZip] = useState("");
    const { selectedMethod, setSelectedMethod } = usePaymentMethod();

    const nameChange = e => {
        setName(e.target.value);
    };
    const phoneChange = e => {
        setPhone(e.target.value);
    };
    const emailChange = e => {
        setEmail(e.target.value);
    };
    const addressChange = e => {
        setAddress(e.target.value);
    };
    const stateChange = e => {
        setState(e.target.value);
    };
    const townshipChange = e => {
        // console.log(e.target.value)
        setTownship(e.target.value);
    };
    const streetChange = e => {
        setStreet(e.target.value);
    };

    const noteChange = e => {
        setNote(e.target.value);
    };

    const fragileChange = e => {
        setFragile(e.target.checked);
    };

    const insuranceChange = e => {
        setInsurance(e.target.checked);
    };

    const gateChange = e => {
        setGate(e.target.value);
    };

    const finalizeChange = e => {
        setFinalize(e.target.value);
    };

    const frontOrHandChange = e => {
        setFrontOrHand(e.target.value);
    };

    const countryChange = e => {
        setCountry(e.target.value);
    };

    const zipChange = e => {
        setZip(e.target.value);
    };

    const [base64String, setBase64String] = useState("");
    const [selectedFile, setSelectedFile] = useState("");

    const [base64Wave, setBase64Wave] = useState("");
    const [selectedFileWave, setSelectedFileWave] = useState("");

    // console.log(base64String);

    const handleFileUpload = event => {
        const file = event.target.files[0];

        setSelectedFile(event.target.files[0]);
        const data = new FileReader();
        data.onload = () => {
            var startIndex = 23;
            if (file.name.toLowerCase().endsWith(".png")) {
                startIndex = 22;
            }

            setBase64String(
                data.result.substring(startIndex, data.result.length)
            );
        };
        data.readAsDataURL(file);
        // const formData = new FormData();
        // formData.append("payment-transfer-image", file);
    };

    const handleFileUploadWave = event => {
        const file = event.target.files[0];

        setSelectedFileWave(event.target.files[0]);
        const data = new FileReader();
        data.onload = () => {
            var startIndex = 23;
            if (file.name.toLowerCase().endsWith(".png")) {
                startIndex = 22;
            }

            setBase64Wave(
                data.result.substring(startIndex, data.result.length)
            );
        };
        data.readAsDataURL(file);
        // const formData = new FormData();
        // formData.append("payment-transfer-image", file);
    };

    // useEffect(() => {
    //     const token = localStorage.getItem("access_token");
    //     (async () => {
    //         const contact = await getShippingContact(token);
    //         // console.log(contact);
    //         setName(contact.name);
    //         setPhone(contact.primaryPhone);
    //         setEmail(contact.email);
    //         setAddress(contact.address);
    //         setStreet(contact.street);
    //         setState(contact.stateGuid);
    //         setTownship(contact.townshipGuid);
    //     })();
    // }, []);

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        (async () => {
            const addresses = await getAllShippingContacts(token);
            setOldAddresses(addresses);
        })();
    }, []);

    // console.log(oldAddresses);

    useEffect(() => {
        (async () => {
            const states = await getAllStates();
            const townships = await getTownshipsWithState(state);
            const countries = await getAllCountries();
            setStates(states.data.items);
            setTownships(townships.data.items);
            setCountries(countries?.data?.items);
        })();
    }, []);

    // Summary
    const storedCart = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : null;
    const cartPrice = storedCart?.reduce((acc, item) => {
        const itemPrice = item.priceSell || 0;
        const itemQuantity = item.cartQty || 1;
        const addonsValue = item.addons.reduce(
            (addonAcc, addon) => addonAcc + (addon.value || 0),
            0
        );
        return acc + itemPrice * itemQuantity + addonsValue;
    }, 0);

    const storedBuy = localStorage.getItem("currentBuy")
        ? JSON.parse(localStorage.getItem("currentBuy"))
        : null;
    const buyPrice = storedBuy?.reduce((acc, item) => {
        const itemPrice = item.priceSell || 0;
        const itemQuantity = item.cartQty || 1;
        const addonsValue = item.addons.reduce(
            (addonAcc, addon) => addonAcc + (addon.value || 0),
            0
        );
        return acc + itemPrice * itemQuantity + addonsValue;
    }, 0);

    const deliPlans = JSON.parse(localStorage.getItem("deliPlan"));
    // const selectedDeliPlan = JSON.parse(
    //     localStorage.getItem("selectedDeliPlan")
    // );

    const uniqueShopGuids = new Set(storedCart?.map(obj => obj.shopGuid));
    const countOfUniqueShopGuids = uniqueShopGuids.size;

    const [subTotal, setSubTotal] = useState(cartPrice || buyPrice);
    const [deliveryCharges, setDeliveryCharges] = useState(0);
    const [total, setTotal] = useState(subTotal + deliveryCharges);
    // const deliPrice = JSON.parse(localStorage.getItem("selectedPlan"));
    // console.log(deliveryCharges + subTotal);
    // console.log(total)
    // console.log(total);
    // console.log(deliPrice)

    // deliPlans?.map(plan => {
    //     if (plan.id === parseInt(selectedDeliPlan)) {
    //         setDeliveryCharges(plan.totalPrice);
    //     }
    //     return null;
    // });

    // console.log(deliveryCharges)

    // console.log(countOfUniqueShopGuids);

    // const cartData = JSON.parse(localStorage.getItem("cart"));
    const cartData = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : null;
    const CurrentBuyData = localStorage.getItem("currentBuy")
        ? JSON.parse(localStorage.getItem("currentBuy"))
        : null;
    // console.log(cartData);

    // useEffect(() => {
    //     const selectedDeliPlan = localStorage.getItem("selectedPlan")
    //     ? JSON.parse(localStorage.getItem("selectedPlan"))
    //     : null;
    //     console.log(selectedDeliPlan);
    // }, []);

    // Start the interval and store the ID
    const intervalId = setInterval(() => {
        // console.log("This function runs every second.");
        const selectedDeliPlan = localStorage.getItem("selectedPlan")
            ? JSON.parse(localStorage.getItem("selectedPlan"))
            : null;
        setDeliveryCharges(
            selectedDeliPlan?.totalPrice * countOfUniqueShopGuids
        );

        return selectedDeliPlan;
        // console.log(selectedDeliPlan);
    }, 1000);

    // Stop the interval after 5 seconds (for example)
    setTimeout(() => {
        clearInterval(intervalId);
        // setTotal(
        //     parseInt(subTotal) +
        //         parseInt(deliveryCharges) * parseInt(countOfUniqueShopGuids)
        // );
        console.log("Interval stopped after 50 seconds.");
    }, 20000);

    const token = localStorage.getItem("access_token");

    // const deliMethods = JSON.parse(localStorage.getItem("deliMethod"));

    useEffect(() => {
        deliPlans?.map(deli => {
            // setDeliveryCharges(deliveryCharges + deli.totalPrice);
            setTotal(subTotal + deli.totalPrice);
            return deli;
        });
        // setDeliveryCharges(selectedDeliPlan?.totalPrice);
    }, [deliPlans]);

    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const handleAgree = event => {
        setIsTermsAccepted(event.target.checked);
    };

    const storedDeli = deliveryCharges / countOfUniqueShopGuids;
    // console.log(storedDeli)

    // const [prodGuid, setProdGuid] = useState("");
    // const [quantity, setQuantity] = useState("");
    // const [prodName, setProdName] = useState("");
    // const [shopGuid, setShopGuid] = useState("");

    // useEffect(() => {
    //     cartData?.map(data => {
    //         return [
    //             setProdGuid(data.variant_guid),
    //             setQuantity(data.cartQty),
    //             setProdName(data.displayName),
    //             setShopGuid(data.store_guid),
    //         ];
    //     });
    // }, [cartData]);

    // console.log(shopGuid);
    const [useCurrency, setUseCurrency] = useState({});

    useEffect(() => {
        (async () => {
            const result = await getCurrency();
            setUseCurrency(result?.data?.item[0]);
        })();
    }, []);

    const proceedCheckout = async () => {
        const result = await createOrder(
            name,
            phone,
            email,
            address,
            state,
            township,
            street,
            note,
            total,
            storedDeli,
            cartData || CurrentBuyData,
            token,
            selectedMethod,
            finalize,
            base64String,
            base64Wave,
            country,
            zip
            // prodGuid,
            // quantity,
            // prodName,
            // shopGuid
        );
        if (result) {
            return result.data;
        } else {
            return false;
        }
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <form>
            <Box sx={{ padding: "10px 50px 20px 50px" }}>
                <Grid container sx={{ pt: 4 }} spacing={1}>
                    <Grid item xs={9}>
                        <Box sx={{ width: "100%" }}>
                            <Tabs value={value} onChange={handleChange}>
                                <Tab label="Local Shipping" />
                                <Tab label="Foreign Shipping" />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <LocalDeliveryInformation
                                        name={name}
                                        nameChange={nameChange}
                                        phone={phone}
                                        phoneChange={phoneChange}
                                        email={email}
                                        emailChange={emailChange}
                                        address={address}
                                        addressChange={addressChange}
                                        states={states}
                                        state={state}
                                        stateChange={stateChange}
                                        townships={townships}
                                        township={township}
                                        townshipChange={townshipChange}
                                        street={street}
                                        streetChange={streetChange}
                                        fragile={fragile}
                                        fragileChange={fragileChange}
                                        insurance={insurance}
                                        insuranceChange={insuranceChange}
                                        gate={gate}
                                        gateChange={gateChange}
                                        note={note}
                                        noteChange={noteChange}
                                        base64String={base64String}
                                        oldAddresses={oldAddresses}
                                        selectedAddress={selectedAddress}
                                        setSelectedAddress={setSelectedAddress}
                                        setName={setName}
                                        setPhone={setPhone}
                                        setEmail={setEmail}
                                        setAddress={setAddress}
                                        setStreet={setStreet}
                                        setState={setState}
                                        setTownship={setTownship}
                                    />
                                    {/* {console.log(state)} */}
                                    <CheckoutShopList township={township} />
                                    <CheckoutPaymentMethod
                                        handleFileUpload={handleFileUpload}
                                        handleFileUploadWave={
                                            handleFileUploadWave
                                        }
                                        base64String={base64String}
                                        base64Wave={base64Wave}
                                        selectedFile={selectedFile}
                                        selectedFileWave={selectedFileWave}
                                    />
                                    {/* <CheckoutAdditionNotes /> */}
                                </Box>{" "}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "24px",
                                    }}
                                >
                                    <ForeignDeliveryInfo
                                        country={country}
                                        countries={countries}
                                        countryChange={countryChange}
                                        name={name}
                                        nameChange={nameChange}
                                        phone={phone}
                                        phoneChange={phoneChange}
                                        email={email}
                                        emailChange={emailChange}
                                        address={address}
                                        addressChange={addressChange}
                                        zip={zip}
                                        zipChange={zipChange}
                                        fragile={fragile}
                                        fragileChange={fragileChange}
                                        insurance={insurance}
                                        insuranceChange={insuranceChange}
                                        gate={gate}
                                        gateChange={gateChange}
                                        note={note}
                                        noteChange={noteChange}
                                        base64String={base64String}
                                        base64Wave={base64Wave}
                                        selectedAddress={selectedAddress}
                                        setSelectedAddress={setSelectedAddress}
                                        setName={setName}
                                        setPhone={setPhone}
                                        setEmail={setEmail}
                                        setAddress={setAddress}
                                    />
                                    <CheckoutShopList />
                                    <CheckoutPaymentMethod
                                        handleFileUpload={handleFileUpload}
                                        handleFileUploadWave={
                                            handleFileUploadWave
                                        }
                                    />
                                    <CheckoutAdditionNotes
                                        note={note}
                                        setNote={setNote}
                                        noteChange={noteChange}
                                    />
                                </Box>
                            </TabPanel>
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "24.08px",
                            }}
                        >
                            {/* <Services /> */}

                            {useCurrency ? (
                                <ExchangeRate useCurrency={useCurrency} />
                            ) : (
                                <Typography>Loading...</Typography>
                            )}
                            <TermsAndConditions
                                isTermsAccepted={isTermsAccepted}
                                handleAgree={handleAgree}
                            />
                            <CheckoutSummary
                                subTotal={subTotal}
                                deliveryCharges={deliveryCharges}
                                total={total}
                                proceedCheckout={proceedCheckout}
                                finalizeChange={finalizeChange}
                                frontOrHandChange={frontOrHandChange}
                                isTermsAccepted={isTermsAccepted}
                                name={name}
                                phone={phone}
                                email={email}
                                address={address}
                                township={township}
                                street={street}
                                country={country}
                                zip={zip}
                                useCurrency={useCurrency}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </form>
    );
};

const TabPanel = ({ children, value, index }) => {
    return (
        <Box role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={2}>{children}</Box>}
        </Box>
    );
};

export default Checkout;
