import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ThemedApp from "./theme/ThemeApp";
import { CssBaseline } from "@mui/material";
import DetailProvider from "./DetailProvider";
import { CartProvider } from "./Contexts/CartContext";
import { PaymentMethodProvider } from "./Contexts/PaymentMethodContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemedApp>
                <CartProvider>
                    <PaymentMethodProvider>
                        <DetailProvider>
                            <CssBaseline />
                            <App />
                        </DetailProvider>
                    </PaymentMethodProvider>
                </CartProvider>
            </ThemedApp>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
