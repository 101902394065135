import React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useAuth } from "../../DetailProvider";
import { useNavigate } from "react-router-dom";

export default function ViewMyWishlist() {
    const { whistLists } = useAuth();
    const wish = localStorage.getItem("wishlist");
    const wishlistArray = JSON.parse(wish);
    const navigate = useNavigate();
    const uniqueWishlistArray = Array.from(
        new Set(wishlistArray.map((whistlist) => whistlist.guid))
    ).map((guid) => wishlistArray.find((item) => item.guid === guid));

    console.log(whistLists);

    return (
        <Box>
            {uniqueWishlistArray.map((whistlist) => {
                return (
                    <Box key={whistlist.guid}>
                        <Card
                            onClick={() => {
                                navigate(
                                    `/oneproductdetail/${whistlist.guid}/${whistlist.guid}`
                                );
                            }}
                        >
                            <img
                                src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${whistlist.imageItems[0]?.image}`}
                                style={{ width: "200px", height: "auto" }}
                                alt=""
                            />
                            <CardContent>
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    {whistlist.name}
                                </Typography>
                                <Typography>
                                    {whistlist.priceSell} MMK
                                </Typography>
                            </CardContent>
                        </Card>
                    </Box>
                );
            })}
        </Box>
    );
}
