import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ErrorPage() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                width: "100%",
                height: "60vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Typography variant="h3">Needed to be login!!!!</Typography>
            <Box sx={{ display: "flex" }}>
                <Button
                    variant="outlined"
                    sx={{
                        fontSize: "50px",
                        borderRadius: "30px",
                        mt: 3,
                        mr: 8,
                    }}
                    onClick={() => {
                        navigate(`/login`);
                    }}
                >
                    Login
                </Button>
                <Button
                    variant="contained"
                    sx={{ fontSize: "50px", borderRadius: "30px", mt: 3 }}
                    onClick={() => {
                        navigate(`/register`);
                    }}
                >
                    Register
                </Button>
            </Box>
        </Box>
    );
}
