import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Avatar, Typography } from "@mui/material";
import { getAddOn } from "../../apicalls";
import { useParams } from "react-router-dom";

export default function AddOn({
    addOnSelect,
    setAddOnSelect,
    addOns,
    loading,
}) {
    const [checked, setChecked] = React.useState([]); // Initialize with an empty array

    //   console.log(addOnSelect);

    const handleToggle = value => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

    
        if (currentIndex === -1) {
            newChecked.push(value);
            setAddOnSelect(prev => [
                ...prev,
                addOns.find(item => item.guid === value),
            ]);
        } else {
            newChecked.splice(currentIndex, 1);
            setAddOnSelect(prev => prev.filter(item => item.guid !== value));
        }

        setChecked(newChecked);
    };

    return (
        !loading && (
            <List sx={{ width: "100%" }}>
                {addOns.map((item, index) => {
                    const value = item.guid;
                    const labelId = `checkbox-list-label-${value}`;

                    return (
                        <ListItem
                            key={value}
                            secondaryAction={
                                <Typography variant="h5">
                                    Ks: {item.value}
                                </Typography>
                            }
                            disablePadding
                        >
                            <ListItemButton
                                role={undefined}
                                onClick={handleToggle(value)}
                                dense
                            >
                                <Checkbox
                                    edge="start"
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                                <Avatar
                                    alt="Remy Sharp"
                                    variant="square"
                                    sx={{ width: 50, height: 50 }}
                                    src={item.imageUrl}
                                />
                                <ListItemText
                                    sx={{ pl: 2, fontSize: "20px" }}
                                    id={labelId}
                                    primary={item.name}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        )
    );
}
