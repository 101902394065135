import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { getCurrency } from "../apicalls";

export default function ExchangeCurrency() {
  const [useCurrency, setUseCurrency] = React.useState({});
  const [currentDateTime, setCurrentDateTime] = React.useState(new Date());


  React.useEffect(() => {
    (async () => {
      const result = await getCurrency();
      setUseCurrency(result.data.item[0]);
    })();
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  // Function to format the date in "JUL 7, 01:00 UTC" format
  const formatDate = (dateString) => {
    const options = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZoneName: "short",
    };
    return new Date(dateString).toLocaleString("en-US", options);
  };

  return (
    <Box sx={{ marginTop: "10px" }}>
      <Card sx={{ minWidth: 275 }}>
        <CardContent>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {formatDate(currentDateTime)}
          </Typography>
          <Typography variant="h5">
            1 USD = {useCurrency.rate} MMK
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}
