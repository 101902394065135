import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { getAllProductFromShop } from "../../apicalls";

export default function AllProductFilter({ guid }) {
    const [shops, setShops] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const result = await getAllProductFromShop(guid);
            setShops(result.data.items);
            console.log(result.data.items);
        })();
    }, [guid]);

    return (
        <Box>
            <Grid container spacing={2}>
                {shops?.map(shop => (
                    <Grid item xs={12} sm={6} md={3} key={shop.guid}>
                    <Card
                        sx={{
                            display: "flex",
                            marginRight: "100px",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            minWidth: 250,
                        }}
                        onClick={() => {
                            navigate(
                                `/oneproductdetail/${shop.productGuid}/${shop.productGuid}`
                            );
                        }}
                    >
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="200"
                                image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${shop.photo}`}
                                alt={shop.name}
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <Typography variant="h6" gutterBottom>
                                    {shop.displayName}
                                </Typography>
                                <Divider />
                                <Typography
                                    variant="subtitle1"
                                    color="textSecondary"
                                >
                                    KS: {shop.priceSell * shop.rate}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                ))}
            </Grid>
        </Box>
    );
}
