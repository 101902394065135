import { createContext, useContext, useEffect, useState } from "react";

const CartContext = createContext();

const CartProvider = ({ children }) => {
    const [cart, setCart] = useState([]);
    const [qty, setQty] = useState("");
    const [cartLength, setCartLength] = useState(0);

    useEffect(() => {
        let existingCartItem = localStorage.getItem("cart");
        if (existingCartItem) setCart(JSON.parse(existingCartItem));
    }, []);

    return (
        <CartContext.Provider value={{cart, setCart, qty, setQty, cartLength, setCartLength}}>
            {children}
        </CartContext.Provider>
    );
};

// custom hook
const useCart = () => useContext(CartContext);

export { useCart, CartProvider };
