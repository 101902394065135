import {
    Box,
    Breadcrumbs,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    InputBase,
    Link,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getAllShopCategory } from "../apicalls";
import "../text.css";

export default function AllCategories() {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="black"
            variant="h6"
            href="/"
            onClick={() => navigate("/")}
        >
            Home
        </Link>,
        <Link
            underline="hover"
            variant="h6"
            key="2"
            color="black"
            onClick={() => navigate("")}
        >
            Categories
        </Link>,
    ];
    useEffect(() => {
        (async () => {
            const result = await getAllShopCategory();
            setCategories(result.data.items);
            setLoading(false);
            // console.log(result);
        })();
    }, []);
    return (
        !loading && (
            <Box sx={{ p: "30px 50px 20px 50px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Stack spacing={2}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        pt: "50px",
                    }}
                >
                    <Box>
                        <Typography variant="h5">All Categories</Typography>
                    </Box>
                    <Box>
                        <Paper
                            component="form"
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                border: "solid 1px black",
                                width: 416, // Remove the background color
                                opacity: 0.8,
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search"
                                inputProps={{
                                    "aria-label": "Search",
                                }}
                            />
                            <IconButton
                                type="button"
                                // sx={{ p: "10px" }}
                                aria-label="search"
                            >
                                <SearchIcon />
                            </IconButton>
                            <Divider
                                sx={{ height: 28, m: 0.5 }}
                                orientation="vertical"
                            />
                        </Paper>
                    </Box>
                </Box>
                <Box>
                    <Box sx={{ pt: "30px" }}>
                        <Grid container spacing={1}>
                            {categories.map(category => {
                                return (
                                    <Grid
                                        item
                                        xs={3}
                                        sm={2}
                                        md={2}
                                        key={category.guid}
                                        sx={{ pb: 2 }}
                                    >
                                        <Card
                                            sx={{ maxWidth: 200 }}
                                            onClick={() =>
                                                navigate(
                                                    `/viewproductcategory/${category.guid}`
                                                )
                                            }
                                        >
                                            <CardActionArea>
                                                <CardMedia
                                                    component="img"
                                                    height="140"
                                                    sx={{objectFit:"cover"}}
                                                    image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${category.logo}`}
                                                    alt="category Logo"
                                                />
                                                <CardContent
                                                    sx={{
                                                        textAlign: "center",
                                                        height:"140px",
                                                        mb: -2,
                                                    }}
                                                >
                                                    <Divider sx={{mb:1}}/>
                                                    <Typography
                                                        sx={{fontWeight:400}}
                                                        className="categoryNameText"
                                                    >
                                                        {category.name}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>
                                        </Card>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        )
    );
}
