import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getOrderHistory } from "../../apicalls";

export default function OrderList() {
    const navigate = useNavigate();
    const [orderLists, setOrderLists] = React.useState([]);
    const [loading, setloading] = React.useState(true);

    React.useEffect(() => {
        const guid = localStorage.getItem("guid");
        // console.log(guid);
        if (guid) {
            getOrderHistory(guid)
                .then(result => {
                    setOrderLists(result.data.items);
                    // console.log(result.data.items);
                    setloading(false);
                })
                .catch(error => {
                    console.error("Error fetching order history:", error);
                });
        }
    }, [orderLists.length]);

    function formatDate(dateString) {
        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    }

    return (
        // !loading && (

        <Box>
            {orderLists.map(orderList => {
                return (
                    <Card sx={{ width: 690 }} key={orderList?.guid}>
                        <CardContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    variant="h5"
                                    component="div"
                                >
                                    Order #{orderList?.salesId}
                                </Typography>
                                <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                >
                                    Total MMK : {orderList?.totalAmount}
                                </Typography>
                            </Box>
                            <Typography variant="body2" color="text.secondary">
                                {formatDate(orderList?.createdOn)}
                            </Typography>
                        </CardContent>
                        <Box sx={{ display: "flex" }}>
                            <Avatar
                                alt="Remy Sharp"
                                src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${orderList?.storeLogo}`}
                                sx={{ width: 56, height: 56, marginLeft: 2 }}
                            />
                            <Avatar
                                alt="Remy Sharp"
                                src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${orderList?.courierLogo}`}
                                sx={{ width: 56, height: 56, marginLeft: 2 }}
                            />
                        </Box>

                        <CardActions>
                            <Button
                                size="small"
                                onClick={() => {
                                    navigate(
                                        `/vieworderdetail/${orderList?.guid}`
                                    );
                                }}
                            >
                                View Order Detail
                            </Button>
                        </CardActions>
                    </Card>
                );
            })}
        </Box>
        // )
    );
}
