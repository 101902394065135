import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const TermsAndConditions = ({ isTermsAccepted, handleAgree }) => {
    const navigate = useNavigate();
    // const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // const handleAgree = event => {
    //     setIsTermsAccepted(event.target.checked);
    // };
    return (
        <Card
            sx={{
                display: "flex",
                width: "auto",
                height: "auto",
                alignItems: "left",
                position: "sticky",
            }}
        >
            <CardContent
                sx={{
                    textAlign: "center",
                }}
            >
                <Typography
                    color={"primary"}
                    variant="body1"
                    component="p"
                    sx={{
                        fontSize: "16px",
                        lineHeight: "140%",
                    }}
                    align="start"
                >
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isTermsAccepted}
                                    onChange={handleAgree}
                                />
                            }
                            label={
                                <Link
                                    to="/TermsAndConditions"
                                    style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                    }}
                                >
                                    I agree to all the terms and conditions.
                                    {/* Terms &amp; Conditions of warranty agreement
                                    and others. */}
                                </Link>
                            }
                        />
                    </FormGroup>
                </Typography>
                {errorMessage && (
                    <Typography sx={{ color: "red", my: 2 }}>
                        {errorMessage}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default TermsAndConditions;
