import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./Pages/Auth/Login";
import HomePage from "./Pages/HomePage";
import Register from "./Pages/Auth/Register";
import { Box } from "@mui/material";
import Header from "./Components/Header";
import ViewCoupon from "./Pages/ViewCoupon";
import AllShops from "./Pages/AllShops";
import ViewProductCategory from "./Pages/ViewProductCategory";
import { getAllShopCategory } from "./apicalls";
import OneProductDetail from "./Pages/OneProductDetail";
import ShoppingCart from "./Pages/ShoppingCart";
import AllCategories from "./Pages/AllCategories";
import Checkout from "./Pages/Checkout";
import ViewOneShop from "./Pages/ViewOneShop";
import Footer from "./Components/Footer/Footer";
import CheckoutSuccessful from "./Pages/CheckoutSuccessful";
import MyAccount from "./Pages/MyAccount";
import TermsAndConditions from "./TermsAndConditions";
import MainDrawer from "./MainDrawer";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import ViewOrderDetail from "./Components/Account/ViewOrderDetail";
import OTPForm from "./Pages/Auth/OTPForm";

const App = () => {
    const location = useLocation();
    const [categories, setCategories] = useState([
        { id: 1, name: "ABC", description: "Lorem Lorem Lorem..." },
    ]);

    const [showDrawer, setShowDrawer] = useState(false);

    const toggleDrawer = () => event => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setShowDrawer(!showDrawer);
    };

    useEffect(() => {
        (async () => {
            const result = await getAllShopCategory();
            setCategories(result.data.items);
        })();
    }, []);
    return (
        <Box>
            <MainDrawer showDrawer={showDrawer} toggleDrawer={toggleDrawer} />
            {location.pathname !== "/login" &&
                location.pathname !== "/verify-account" &&
                location.pathname !== "/register" && (
                    <Header toggleDrawer={toggleDrawer} />
                )}
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/verify-account" element={<OTPForm />} />
                <Route path="/viewCoupon" element={<ViewCoupon />} />
                <Route path="/allshops" element={<AllShops />} />
                <Route
                    path="/viewproductcategory/:guid"
                    element={<ViewProductCategory categories={categories} />}
                />
                <Route
                    path="/oneproductdetail/:cguid/:pguid"
                    element={<OneProductDetail />}
                />
                <Route path="/allshops" element={<AllShops />} />
                <Route path="/allcategories" element={<AllCategories />} />
                <Route
                    path="/viewproductcategory/:guid"
                    element={<ViewProductCategory categories={categories} />}
                />
                <Route path="/add-to-cart" element={<ShoppingCart />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route
                    path="/checkout-successful/:id"
                    element={<CheckoutSuccessful />}
                />
                <Route path="/" element={<ViewOneShop />} />
                <Route path="/my-account" element={<MyAccount />} />
                <Route
                    path="/TermsAndConditions"
                    element={<TermsAndConditions />}
                />
                <Route path="/about" element={<AboutUs />} />
                <Route path="/contact" element={<ContactUs />} />
                <Route
                    path="/vieworderdetail/:guid"
                    element={<ViewOrderDetail />}
                />
            </Routes>

            {location.pathname !== "/login" &&
                location.pathname !== "/verify-account" &&
                location.pathname !== "/register" && <Footer />}
        </Box>
    );
};

export default App;
