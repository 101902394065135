import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, Button } from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import { getShopFromDetail } from "../../apicalls";
import { useNavigate } from "react-router-dom";

export default function DetailShopName({ pguid }) {
    const [shopName, setShopName] = React.useState({});
    const navigate = useNavigate();
    

    React.useEffect(() => {
        (async () => {
            const result = await getShopFromDetail(pguid);
            if (result.data.items) {
                setShopName(result.data.items[0]);
                // console.log(result.data.items[0]);
            }
        })();
    }, [pguid]);

    return (
        <>
            {shopName && (
                <Card
                    sx={{ display: "flex", padding: "10px", marginTop: "10px" }}
                >
                    <Box>
                        <Avatar
                            alt="Remy Sharp"
                            src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${shopName?.logo}`}
                            sx={{
                                width: 56,
                                height: 56,
                                display: "inline-block",
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                display: "inline-block",
                            }}
                        >
                            <CardContent sx={{ flex: "1 0 auto" }}>
                                <Typography component="div" variant="h5">
                                    {shopName.name}
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    color="text.secondary"
                                    component="div"
                                >
                                    {shopName.sellQuantity} Item(s) Sold
                                </Typography>
                            </CardContent>
                        </Box>
                        <Box>
                            <a href={"http://"+shopName.website}>
                                <Button
                                    variant="outlined"
                                    startIcon={<StoreIcon />}
                                    sx={{ width: "310px", marginLeft: "10px" }}
                                >
                                    Visit
                                </Button>
                            </a>
                        </Box>
                    </Box>
                </Card>
            )}
        </>
    );
}
