import {
    Box,
    Breadcrumbs,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { getAllProducts } from "../apicalls";

export default function ViewProductCategory({ categories }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { guid } = useParams();
    const sort = "Sorted By - ";
    const [oneCategory, setOneCategory] = useState([]);
    const [products, setProducts] = useState([]);
    const [count, setCount] = useState(0);
    // Update with your product data
    const [page, setPage] = useState(1);
    const productsPerPage = 12;
    const totalProducts = products.length;
    const totalPages = Math.ceil(totalProducts / productsPerPage);

    // Find the category object with matching guid
    const selectedCategory = categories.find(
        category => category.guid === guid
    );

    // Set the selected category to oneCategory
    useEffect(() => {
        if (selectedCategory) {
            setOneCategory(selectedCategory);
            // console.log(selectedCategoryc);
        }
    }, [selectedCategory]);

    const breadcrumbs = [
        <Link
            underline="hover"
            key="1"
            color="black"
            variant="h6"
            href="/"
            onClick={() => navigate("/")}
        >
            Home
        </Link>,
        <Link
            underline="hover"
            variant="h6"
            key="2"
            color="black"
            onClick={() => navigate("")}
        >
            Category
        </Link>,
    ];

    // Function to handle page change
    const handlePageChange = (event, value) => {
        setPage(value);
    };

    // Calculate the start and end index for the current page
    const startIndex = (page - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;

    useEffect(() => {
        (async () => {
            const result = await getAllProducts(guid);
            setProducts(result.data.items);
            setLoading(false);
            setCount(result.data.items.length);
            console.log(result.data.items);
            // console.log(result);
        })();
    }, [guid]);

    return (
        !loading && (
            <Box sx={{ p: "30px 50px 20px 50px" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Stack spacing={2}>
                        <Breadcrumbs separator="›" aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Stack>
                </Box>
                <Box>
                    <Grid container sx={{ pt: 4 }} spacing={2}>
                        <Grid item xs={12} sm={3}>
                            <Typography variant="h5">Filter By</Typography>
                            <FormControl
                                variant="standard"
                                sx={{ m: 1, minWidth: 220 }}
                            >
                                <InputLabel id="demo-simple-select-standard-label">
                                    Categories
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={oneCategory.guid} // Set the value to the selected category's guid
                                    label=""
                                    onChange={event => {
                                        const selectedGuid = event.target.value;
                                        const selectedCategory =
                                            categories.find(
                                                category =>
                                                    category.guid ===
                                                    selectedGuid
                                            );
                                        setOneCategory(selectedCategory);
                                        navigate(
                                            `/viewproductcategory/${selectedCategory.guid}`
                                        );
                                    }}
                                >
                                    {categories.map(category => (
                                        <MenuItem
                                            key={category.guid}
                                            value={category.guid}
                                        >
                                            {category.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    flexDirection: "column",
                                    sm: { flexDirection: "row" },
                                }}
                            >
                                <Box>
                                    <Typography>
                                        {count} Items found in "
                                        {oneCategory.name}"
                                    </Typography>
                                </Box>
                                <Box>
                                    <Box>
                                        <Typography>
                                            {/* {sort} */}
                                            {/* <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={oneCategory.guid} // Set the value to the selected category's guid
                                                label=""
                                                onChange={event => {
                                                    const selectedGuid =
                                                        event.target.value;
                                                    const selectedCategory =
                                                        categories.find(
                                                            category =>
                                                                category.guid ===
                                                                selectedGuid
                                                        );
                                                    setOneCategory(
                                                        selectedCategory
                                                    );
                                                }}
                                            >
                                                {categories.map(category => (
                                                    <MenuItem
                                                        key={category.guid}
                                                        value={category.guid}
                                                    >
                                                        {category.name}
                                                    </MenuItem>
                                                ))}
                                            </Select> */}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ pt: 3 }}>
                                <Grid container spacing={1}>
                                    {products
                                        .slice(startIndex, endIndex)
                                        .map(product => (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                key={product.guid}
                                            >
                                                <Card
                                                    sx={{
                                                        maxWidth: 250,
                                                        height: 240,
                                                        mt: 1,
                                                    }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/oneproductdetail/${guid}/${product.guid}`
                                                        )
                                                    }
                                                >
                                                    <CardActionArea>
                                                        <CardMedia
                                                            component="img"
                                                            height="170"
                                                            image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${product.image}`}
                                                            alt="green iguana"
                                                        />
                                                        <CardContent
                                                            sx={{
                                                                textAlign:
                                                                    "center",
                                                                mb: 1,
                                                            }}
                                                        >
                                                            <Divider />
                                                            <Typography
                                                                variant="h5"
                                                                className="shopNameText"
                                                            >
                                                                {
                                                                    product.displayName
                                                                }
                                                            </Typography>
                                                            <Typography
                                                                variant="h7"
                                                                className="shopNameText"
                                                            >
                                                                {product.priceSell *
                                                                    product.rate}{" "}
                                                                - Kyats
                                                            </Typography>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>
                                        ))}
                                </Grid>
                            </Box>
                            {totalPages > 1 && (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        pt: 3,
                                    }}
                                >
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    );
}
