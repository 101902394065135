import React, { useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    InputAdornment,
    OutlinedInput,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const Summary = ({ useCurrency }) => {
    const storedCart = localStorage.getItem("cart")
        ? JSON.parse(localStorage.getItem("cart"))
        : [];
    // const priceArray = storedCart?.map(
    //     item => item.priceSell * item.rate * item.cartQty
    // );
    // let cartPrice = priceArray?.reduce((a, current) => {
    //     return a + current;
    // }, 0);
    const totalSum = storedCart.reduce((acc, item) => {
        const itemPrice = item.priceSell || 0;
        const itemQuantity = item.cartQty || 1;
        const addonsValue = item.addons.reduce(
            (addonAcc, addon) => addonAcc + (addon.value || 0),
            0
        );
        return acc + itemPrice * itemQuantity + addonsValue;
    }, 0);
    const [subTotal, setSubTotal] = useState(totalSum);
    const [deliCost, setDeliCost] = useState(0);
    const [total, setTotal] = useState(subTotal + deliCost);
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Card
                sx={{
                    width: 400,
                    height: "auto",
                    alignItems: "left",
                    position: "sticky",
                }}
            >
                <CardContent
                    sx={{
                        textAlign: "center",
                        mb: 1,
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                display: "flex",
                                fontWeight: "bold",
                            }}
                        >
                            Summary
                        </Typography>
                        <Box sx={{ marginY: "15px" }}>
                            <Typography
                                sx={{
                                    marginBottom: "5px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Subtotal</span>
                                <span>
                                    {subTotal ? subTotal.toLocaleString() : 0}{" "}
                                    Ks
                                </span>
                            </Typography>
                            {/* <Typography
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Delivery Cost</span>
                                <span>$$$</span>
                            </Typography> */}
                        </Box>
                        <Box>
                            <OutlinedInput
                                type="text"
                                placeholder="Enter Coupon Code"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                alert(
                                                    "This feature is available for Mobile App."
                                                )
                                            }
                                        >
                                            Apply
                                        </Button>
                                    </InputAdornment>
                                }
                            />
                        </Box>
                        <Box sx={{ marginY: "20px" }}>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    marginBottom: "5px",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Total Exchange Rate</span>
                                <span>{total / useCurrency?.rate} $</span>
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: "bold",
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <span>Total</span>
                                <span>
                                    {total ? total.toLocaleString() : 0} Ks
                                </span>
                            </Typography>
                        </Box>
                        {storedCart[0] && token ? (
                            <Button
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    navigate("/checkout");
                                }}
                            >
                                Proceed to Checkout
                            </Button>
                        ) : (
                            <Button variant="contained" fullWidth>
                                Proceed to Checkout
                            </Button>
                        )}
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Summary;
