import { Box, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import NearMeIcon from '@mui/icons-material/NearMe';

export default function FooterLogo() {
    return (
        <Box>
            <img
                src="/images/GIF-93984321015cac36832ac1d9772b8f55.gif"
                width="100"
                height="auto"
                alt=""
            />
            <List>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <LocalPhoneIcon sx={{color:"white"}}/>
                    </ListItemIcon>
                    <ListItemText primary="09404477118 , 09250024653" />
                </ListItem>
                <ListItem disablePadding>
                    <ListItemIcon>
                        <MailIcon sx={{color:"white"}}/>
                    </ListItemIcon>
                    <ListItemText primary="Mails" />
                </ListItem>
            </List>
        </Box>
    );
}
