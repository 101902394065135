import React, { useEffect, useState } from "react";
import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardMedia,
    Divider,
    Typography,
} from "@mui/material";
import { KeyboardArrowRight as KeyboardArrowRightIcon } from "@mui/icons-material";
import LocalDeliveryMethod from "./LocalDeliveryMethod";
import { getCurrency } from "../../apicalls";

const CheckoutShopCart = ({ product, township }) => {
    // console.log(shopGuid)
    const deliPlans = JSON.parse(localStorage.getItem("deliPlan"));
    // console.log(deliPlans);
    const selectedDeliPlan = JSON.parse(
        localStorage.getItem("selectedDeliPlan")
    );

    const [open, setOpen] = useState(false);

    const handleOpenModal = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (deliPlans && deliPlans.length > 0) {
            const selectedPlan = deliPlans.find(
                plan => plan.id === selectedDeliPlan
            );
            localStorage.setItem("selectedPlan", JSON.stringify(selectedPlan));
        } else {
            localStorage.setItem("selectedPlan", JSON.stringify(null));
        }
    }, [selectedDeliPlan, deliPlans]);

    const handleCloseModal = () => {
        setOpen(false);
        // const selectedPlan = deliPlans.find(
        //     plan => plan.id === selectedDeliPlan
        // );
        // // console.log(selectedPlan)
        // return localStorage.setItem(
        //     "selectedPlan",
        //     JSON.stringify(selectedPlan)
        // );
        // window.location.reload();
    };
    const [useCurrency, setUseCurrency] = React.useState({});
    useEffect(() => {
        (async () => {
            const result = await getCurrency();
            setUseCurrency(result.data.item[0]);
        })();
    }, []);

    const productPrice = product.priceSell * product.rate;

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 24px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                    fullWidth
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <Avatar
                            variant="square"
                            sx={{
                                width: "60px",
                                height: "60px",
                            }}
                            src={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${product?.shopLogo}`}
                        />
                        <Typography
                            variant="subtitle1"
                            sx={{
                                marginX: "10px",
                                fontWeight: "bold",
                            }}
                        >
                            {product?.shopName}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: "24px",
                    padding: "16px 24px",
                }}
            >
                {/*  */}
                <Card sx={{ width: "200px" }}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            image={`https://dev2.mtlexpress.com.mm/qq-delivery-backend/v1/image/download/${
                                product.images ? product.images[0] : ""
                            }`}
                            alt="Product Image"
                        />
                    </CardActionArea>
                </Card>
                {/*  */}
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                marginRight: 50,
                            }}
                        >
                            <Typography variant="h6">
                                {/* OPPO A31, Ram 8GB+ Rom 128GB; 4G LTE; Fingerprint;
                        Camera 12MP+2MP+2MP; Front 8MP */}
                                {product.productName}
                            </Typography>
                            <Typography color="secondary">
                                {product.name}
                                {product.weight
                                    ? ` / ${product.weight} Kg`
                                    : ""}
                            </Typography>
                        </Box>
                        {/*  */}
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "8px",
                                width: "20%",
                            }}
                        >
                            <Typography>
                                Ks {productPrice.toLocaleString()}
                            </Typography>
                            <Typography variant="body2" color={"secondary"}>
                                (Approximately{" "}
                                {(product.priceSell / useCurrency.rate).toFixed(
                                    2
                                )}
                                $)
                            </Typography>
                            <Typography fontWeight={"bold"}>
                                Quantity: {product.cartQty}{" "}
                            </Typography>
                        </Box>
                    </Box>

                    {product?.addons[0] ? (
                        <Box sx={{ marginTop: 3 }}>
                            <Divider />
                            <Box sx={{ marginTop: 3 }}>
                                <Typography variant="h6" color={"secondary"}>
                                    Add ons
                                </Typography>
                                <Typography
                                    variant="h6"
                                    sx={{ cursor: "pointer" }}
                                >
                                    {product?.addons[0]?.name}
                                </Typography>
                                <Typography>
                                    {product?.addons[0]?.value?.toLocaleString()}{" "}
                                    Ks
                                </Typography>
                                <Typography variant="body2" color={"secondary"}>
                                    (Approximately{" "}
                                    {(
                                        (product?.addons[0]?.value *
                                            product.rate) /
                                        useCurrency.rate
                                    ).toFixed(2)}{" "}
                                    $)
                                </Typography>
                            </Box>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            </Box>
            <CardActionArea onClick={handleOpenModal}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "16px 24px",
                        gap: "16px",
                        alignSelf: "stretch",
                    }}
                >
                    {deliPlans?.map(plan => {
                        if (plan.id === parseInt(selectedDeliPlan)) {
                            return (
                                <Box
                                    key={plan.id} // Add a unique key for each rendered element
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Typography>
                                        {plan.plan}: {plan.totalPrice} Ks
                                    </Typography>
                                    <Typography>
                                        Estimated time: 30 mins
                                    </Typography>
                                </Box>
                            );
                        }

                        // Return null for plans that don't match the condition
                        return null;
                    })}

                    <Box>
                        <KeyboardArrowRightIcon />
                    </Box>
                </Box>
            </CardActionArea>
            <LocalDeliveryMethod
                product={product}
                open={open}
                handleCloseModal={handleCloseModal}
                township={township}
            />
        </>
    );
};

export default CheckoutShopCart;
