import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const TermsAndConditions = () => {
  const termsAndConditionsText = `
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et neque eu libero tincidunt interdum non eu felis.
    Pellentesque eget purus eu velit facilisis euismod. Proin tristique urna a ex lobortis malesuada.
    Sed non libero ultricies, porttitor libero vel, ultricies urna. Nulla facilisi.
    Sed ullamcorper urna vel sem lacinia bibendum. Integer et eros nulla.
    Quisque a nisi a arcu cursus faucibus. Ut in augue purus. Morbi dapibus facilisis tortor eget posuere.
    Proin feugiat a purus vel pulvinar. In finibus bibendum libero, nec dignissim augue iaculis vel.
    Sed euismod felis eu enim hendrerit bibendum. Sed pulvinar pellentesque ex at fringilla.
    Proin ut nunc orci. Quisque et feugiat orci. Nulla facilisi. Curabitur a nunc nibh.
    Etiam in ultricies nibh, vitae ullamcorper neque. Donec quis dapibus lectus. Cras posuere dictum felis, nec fermentum justo faucibus ut.
    Nunc dignissim enim nec laoreet lacinia. Nulla facilisi. Mauris sit amet tellus ac nulla rhoncus posuere.
    Vestibulum feugiat turpis vel dui bibendum, non vestibulum arcu venenatis.
  `;

  return (
    <Box p={2}>
      <Typography variant="h4" gutterBottom>
        Terms and Conditions
      </Typography>
      <Typography variant="body1" paragraph>
        {termsAndConditionsText}
      </Typography>
    </Box>
  );
};

export default TermsAndConditions;
