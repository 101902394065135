import React from "react";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";

const ForeignDeliveryInfo = ({
    country,
    countryChange,
    countries,
    name,
    nameChange,
    phone,
    phoneChange,
    email,
    emailChange,
    address,
    addressChange,
    zip,
    zipChange,
    fragile,
    fragileChange,
    insurance,
    insuranceChange,
    gate,
    gateChange,
    note,
    noteChange,
    base64String,
    oldAddresses,
    selectedAddress,
    setSelectedAddress,
    setName,
    setPhone,
    setEmail,
    setAddress,
    setStreet,
    setState,
    setTownship,
}) => {
    return (
        <>
            <Card sx={{ maxWidth: "full" }} elevation={1}>
                <CardContent>
                    <form>
                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                            Delivery Information
                        </Typography>
                        <Box
                            sx={{
                                gap: "24px",
                            }}
                        >
                            <TextField
                                sx={{
                                    marginTop: "16px",
                                }}
                                label="Full Name"
                                value={name}
                                onChange={nameChange}
                                fullWidth
                                required
                            />
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "24px",
                                    marginY: "16px",
                                }}
                                fullWidth
                            >
                                <TextField
                                    label="Phone Number"
                                    type="phone"
                                    sx={{
                                        width: "50%",
                                    }}
                                    value={phone}
                                    onChange={phoneChange}
                                    required
                                />
                                <TextField
                                    label="Email"
                                    type="email"
                                    sx={{
                                        width: "50%",
                                    }}
                                    value={email}
                                    onChange={emailChange}
                                    required
                                />
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    gap: "24px",
                                    marginY: "16px",
                                }}
                                fullWidth
                            >
                                <FormControl sx={{ width: "50%" }} required>
                                    <InputLabel id="demo-simple-select-label">
                                        Country
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="State"
                                        value={country}
                                        onChange={countryChange}
                                    >
                                        {countries?.map(country => (
                                            <MenuItem
                                                key={country.key}
                                                value={country.guid}
                                            >
                                                {country.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Postcode / ZIP"
                                    sx={{
                                        width: "50%",
                                    }}
                                    value={zip}
                                    onChange={zipChange}
                                    required
                                />
                            </Box>
                            <TextField
                                label="Address"
                                fullWidth
                                value={address}
                                onChange={addressChange}
                                required
                            />
                        </Box>
                    </form>
                </CardContent>
            </Card>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Card
                    sx={{
                        width: "100%",
                        height: "auto",
                        alignItems: "left",
                        position: "sticky",
                    }}
                >
                    <CardContent
                        sx={{
                            // textAlign: "center",
                            mb: 1,
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    display: "flex",
                                    fontWeight: "bold",
                                }}
                            >
                                Services
                            </Typography>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={insurance}
                                            onChange={insuranceChange}
                                        />
                                    }
                                    label="Warranty Service Request"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={fragile}
                                            onChange={fragileChange}
                                        />
                                    }
                                    label="Product maintenance (Fragile)"
                                />
                            </FormGroup>
                            {/* <Typography
                                variant="body1"
                                component="p"
                                sx={{
                                    fontSize: "16px",
                                    lineHeight: "140%",
                                }}
                                align="start"
                            >
                                Lorem ipsum dolor sit amet consectetur. In amet
                                ac turpis non. Ut molestie sit{" "}
                            </Typography> */}
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default ForeignDeliveryInfo;
