import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import ViewMyAccount from "./ViewMyAccount";
import ViewMyOrder from "./ViewMyOrder";
import ViewMyWishlist from "./ViewMyWishlist";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function TabsForAccount() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    return label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "flex",
        margin: "10px",
      }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{
          borderRight: 1,
          borderColor: "divider",
          padding: "10px",
          "& .MuiTabs-scroller": {
            "& .MuiTabs-indicator": {
              display: "none",
            },
          },
        }}
      >
        <Typography variant="h6">Account</Typography>
        <Divider />
        <Tab label={formatLabel("My Account")} {...a11yProps(0)} />
        <Tab label={formatLabel("My Order")} {...a11yProps(1)} />
        <Tab label={formatLabel("My Wishlist")} {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <ViewMyAccount />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ViewMyAccount />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ViewMyAccount />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ViewMyOrder />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ViewMyWishlist />
      </TabPanel>
    </Box>
  );
}
